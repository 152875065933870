@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Inter", sans-serif;
}

::-webkit-scrollbar {
  display: none;
}

body {
  color: #ffffff;
  background: #262627;
}

#root {
  min-height: 100vh;
}
